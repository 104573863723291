import {
	BatchClassApi,
	Configuration,
	DEXPApi,
	DocumentApi,
	DocumentClassApi,
	EMailApi,
	ExportApi,
	ExtractionApi,
	FormTrainingApi,
	ImportApi,
	JobsApi,
	LocatorApi,
	LogApi,
	MasterDataApi,
	OAuth2Type,
	PublicApi,
	QueueApi,
	ReportApi,
	RoleApi,
	ScriptingApi,
	SearchApi,
	SystemApi,
	ThemeApi,
	TrainingApi,
	TranslationsApi,
	User,
	UserApi,
	ValidationApi,
	XMLApi
} from "@dex/squeeze-client-ts";
import {TenantsApi} from "@dex/squeeze-system-client-ts";
import * as Keycloak from 'keycloak-js';
import {KeycloakInitOptions, KeycloakInstance} from 'keycloak-js';
import router from "@/router";
import {useI18n} from "vue-i18n";

export enum AuthTypes {
	Basic,
	Bearer,
}

export type SqueezeApis = {
	batchClass: BatchClassApi;
	dexp: DEXPApi;
	document: DocumentApi;
	documentClass: DocumentClassApi;
	email: EMailApi;
	export: ExportApi;
	extraction: ExtractionApi;
	import: ImportApi;
	jobs: JobsApi;
	locator: LocatorApi;
	log: LogApi;
	masterData: MasterDataApi;
	public: PublicApi;
	queue: QueueApi;
	report: ReportApi;
	role: RoleApi;
	scripting: ScriptingApi;
	search: SearchApi;
	system: SystemApi;
	theme: ThemeApi;
	user: UserApi;
	training: TrainingApi;
	validation: ValidationApi;
	translations: TranslationsApi;
	formTraining: FormTrainingApi;
	xml: XMLApi;
}

export type SqueezeSystemApis = {
	tenants: TenantsApi;
}

/**
 * This class is our monolithic implementation for everything API related. You can use this if you require calling a SQUEEZE
 * HTTP API.
 *
 * If you require interaction with any other DEXP API, consider NOT adding it to this ClientManager. Moving forward, we should
 * consider if having a single god-class for API interactions is a sensible idea.
 */
export class ClientManager {

	public readonly login: {
		portalAvailable: boolean;
		keyCloakAvailable: boolean;
		basicAvailable: boolean;
		activeAuth: AuthTypes | null;
	}

	/**
	 * Contains API methods to interact with SQUEEZE
	 */
	public readonly squeeze: SqueezeApis;

	/**
	 * Contains API methods to interact with SQUEEZE System
	 */
	public readonly squeezeSystem: SqueezeSystemApis;

	/**
	 * Contains configuration related to the SQUEEZE viewer
	 */
	public readonly viewer: {
		basePath: string;
	}

	/**
	 * Contains the configuration for KeyCloak
	 */
	public readonly keycloakOptions: {
		url: string;
		realm: string;
		clientId: string;
		onLoad: string;
		redirectUri: string;
		token: string;
		refreshToken: string;
	}

	/** Objekct for Keycloak */
	public keycloak: KeycloakInstance | null = null;

	private readonly sqzConfig: any = {}

	/** Defines an own fetch to be used */
	public customFetch: typeof fetch;

	/** Defines an own fetch to be by Freeze */
	public freezeFetch: typeof fetch;
	public freezeFetchNoAuth: typeof fetch;
	public licenceFetch: typeof fetch;

	/** Defines an own fetch to be by System (API) */
	public systemFetch: typeof fetch;

	private readonly baseUrl: string;

	private constructor(url: string | null, apiKey: string | null) {
		const { locale } = useI18n({ useScope: 'global' });
		let baseUrl = window.location.origin;
		if (url) {
			baseUrl = url;
		}
		this.baseUrl = baseUrl;

		this.sqzConfig = {
			v2: {
				basePath: `${baseUrl}/api/v2`,
				// username: "",
				// password: "",
			},
			system: {
				basePath: `http://system.docker.localhost/api/system/v1`,
			},
		} as Configuration;

		if (apiKey) {
			this.sqzConfig.apiKey = apiKey;
		}

		/**
		 * This is a customized fetched, that is extended when using keycloak
		 * @param input
		 * @param requestInit
		 */
		this.customFetch = async (input: any, requestInit?: RequestInit) => {
			if (!requestInit) {
				requestInit = {};
			}

			if (!requestInit.headers) {
				requestInit.headers = new Headers();
			}

			// Always set Authorization Header if the Mode is development
			if (process.env.NODE_ENV === "development" && this.sqzConfig.v2.username && this.sqzConfig.v2.password) {
				const headers = new Headers();
				headers.set("Authorization", 'Basic ' + btoa(this.sqzConfig.v2.username + ":" + this.sqzConfig.v2.password));
				requestInit.headers = headers;
			}

			if (this.sqzConfig.apiKey) {
				const headers = new Headers();
				headers.set('X-API-KEY', this.sqzConfig.apiKey);
				requestInit.headers = headers;
			}

			if (this.login.activeAuth === AuthTypes.Bearer) {
				const headers = new Headers();
				headers.set("Authorization", 'Bearer ' + this.keycloakOptions.token);
				requestInit.headers = headers;
			}

			// Always add Translation-Headers, if is translatable. Also the countries endpoint always have to be translated. Same goes for the news endpoint
			if((router.currentRoute.value.meta && router.currentRoute.value.meta.translate) || (input && input.indexOf("/public/translations/countries?onlyActive=true") != -1)
				|| (input && input.indexOf("/dexp-sqz/news"))) {
				const headers = new Headers(requestInit.headers);
				headers.set("X-SQZ-LANG", locale.value);
				headers.set("X-SQZ-TRANSLATE", '1');
				requestInit.headers = headers;
			}

			return await fetch(input, requestInit);
		};

		/**
		 * This is a customized fetched, that is used by functions for Freeze
		 * @param input
		 * @param requestInit
		 */
		this.licenceFetch = async (input: any, requestInit?: RequestInit) => {
			if (!requestInit) {
				requestInit = {};
			}

			if (!requestInit.headers) {
				const headers = new Headers();
				requestInit.headers = headers;
			} else {
				const headers = requestInit.headers as any;
				requestInit.headers = headers;
			}

			// Activate later
			if (this.login.activeAuth === AuthTypes.Bearer) {
				const headers = requestInit.headers as any;
				headers.set("Authorization", 'Bearer ' + this.keycloakOptions.token);
				requestInit.headers = headers;
			}

			return fetch(input, requestInit);
		};

		/**
		 * This is a customized fetched, that is used by functions for Freeze
		 * @param input
		 * @param requestInit
		 */
		this.freezeFetch = async (input: any, requestInit?: RequestInit) => {
			if (!requestInit) {
				requestInit = {};
			}

			if (!requestInit.headers) {
				const headers = new Headers();
				requestInit.headers = headers;
			} else {
				const headers = new Headers(requestInit.headers);
				requestInit.headers = headers;
			}

			if (this.login.activeAuth === AuthTypes.Bearer) {
				const headers = new Headers(requestInit.headers);
				headers.set("Authorization", 'Bearer ' + this.keycloakOptions.token);
				requestInit.headers = headers;
			}

			return fetch(input, requestInit);
		};

		/**
		 * This is a customized fetched, that is used by functions for Freeze
		 * @param input
		 * @param requestInit
		 */
		this.freezeFetchNoAuth = async (input: any, requestInit?: RequestInit) => {
			if (!requestInit) {
				requestInit = {};
			}

			return fetch(input, requestInit);
		};

		/**
		 * This is a customized fetched, that is used by functions for System (API)
		 * @param input
		 * @param requestInit
		 */
		this.systemFetch = async (input: any, requestInit?: RequestInit) => {
			if (!requestInit) {
				requestInit = {};
			}

			if (!requestInit.headers) {
				requestInit.headers = new Headers();
			}

			// Always set Authorization Header
			if (this.sqzConfig.system.username && this.sqzConfig.system.password) {
				const headers = new Headers();
				headers.set("Authorization", 'Basic ' + btoa(this.sqzConfig.system.username + ":" + this.sqzConfig.system.password));
				requestInit.headers = headers;
			}

			return fetch(input, requestInit);
		};

		this.login = {
			portalAvailable: false,
			keyCloakAvailable: false,
			basicAvailable: false,
			activeAuth: null,
		}

		this.squeeze = {
			batchClass: new BatchClassApi(this.sqzConfig.v2, undefined, this.customFetch),
			dexp: new DEXPApi(this.sqzConfig.v2, undefined, this.customFetch),
			document: new DocumentApi(this.sqzConfig.v2, undefined, this.customFetch),
			documentClass: new DocumentClassApi(this.sqzConfig.v2, undefined, this.customFetch),
			email: new EMailApi(this.sqzConfig.v2, undefined, this.customFetch),
			export: new ExportApi(this.sqzConfig.v2, undefined, this.customFetch),
			extraction: new ExtractionApi(this.sqzConfig.v2, undefined, this.customFetch),
			import: new ImportApi(this.sqzConfig.v2, undefined, this.customFetch),
			jobs: new JobsApi(this.sqzConfig.v2, undefined, this.customFetch),
			locator: new LocatorApi(this.sqzConfig.v2, undefined, this.customFetch),
			log: new LogApi(this.sqzConfig.v2, undefined, this.customFetch),
			masterData: new MasterDataApi(this.sqzConfig.v2, undefined, this.customFetch),
			public: new PublicApi(this.sqzConfig.v2, undefined, this.customFetch),
			queue: new QueueApi(this.sqzConfig.v2, undefined, this.customFetch),
			report: new ReportApi(this.sqzConfig.v2, undefined, this.customFetch),
			role: new RoleApi(this.sqzConfig.v2, undefined, this.customFetch),
			scripting: new ScriptingApi(this.sqzConfig.v2, undefined, this.customFetch),
			search: new SearchApi(this.sqzConfig.v2, undefined, this.customFetch),
			system: new SystemApi(this.sqzConfig.v2, undefined, this.customFetch),
			theme: new ThemeApi(this.sqzConfig.v2, undefined, this.customFetch),
			user: new UserApi(this.sqzConfig.v2, undefined, this.customFetch),
			training: new TrainingApi(this.sqzConfig.v2, undefined, this.customFetch),
			validation: new ValidationApi(this.sqzConfig.v2, undefined, this.customFetch),
			translations: new TranslationsApi(this.sqzConfig.v2, undefined, this.customFetch),
			formTraining: new FormTrainingApi(this.sqzConfig.v2, undefined, this.customFetch),
			xml: new XMLApi(this.sqzConfig.v2, undefined, this.customFetch),
		}

		this.squeezeSystem = {
			tenants: new TenantsApi(this.sqzConfig.system, undefined, this.systemFetch),
		}

		this.viewer = {
			basePath: baseUrl,
		}

		this.keycloakOptions = {
			token: '',
			url: '',
			realm: '',
			clientId: 'dexp-frontend',
			onLoad: 'login-required',
			redirectUri: `${baseUrl}/ui/checkLogin`,
			refreshToken: '',
		}
	}

	// region SQUEEZE Client
	/**
	 * Triggers Login with basic authentication.
	 * @param user
	 * @param pwd
	 */
	public async loginBasic(user: string, pwd: string): Promise<boolean> {
		this.setSqueezeCredentials(user, pwd);
		const isLoggedIn = await this.isAuthenticatedAtSqueeze();
		this.setLoginType(AuthTypes.Basic);
		localStorage.setItem("authorization", "basic");

		// Delete User-data from Client-Code after the login has been tried. Only do that on production-builds
		if (process.env.NODE_ENV !== "development") {
			this.unsetSqueezeCredentials();
		}

		return isLoggedIn;
	}

	/**
	 * Triggered system login with the password of server configuration
	 * @param {string} user
	 * @param {string} pwd
	 */
	public async systemAuth(user: string, pwd: string): Promise<boolean> {
		this.setSqzSystemCredentials(user, pwd);
		const isLoggedIn = await this.isAuthenticatedAtSqzSystem();
		this.setLoginType(AuthTypes.Basic);
		localStorage.setItem("authorization", "basic");

		if (process.env.NODE_ENV !== "development") {
			this.unsetSqzSystemCredentials();
		}

		return isLoggedIn;
	}

	/**
	 * Sets the User Credentials
	 * @param user
	 * @param pwd
	 * @private
	 */
	private setSqueezeCredentials(user: string, pwd: string) {
		// taken from https://developer.mozilla.org/en-US/docs/Glossary/Base64#solution_1_%E2%80%93_escaping_the_string_before_encoding_it
		this.sqzConfig.v2.username = unescape(encodeURIComponent(user));
		this.sqzConfig.v2.password = unescape(encodeURIComponent(pwd));
	}

	/**
	 * Deletes the User Credentials
	 * @private
	 */
	private unsetSqueezeCredentials() {
		delete this.sqzConfig.v2.username;
		delete this.sqzConfig.v2.password;
	}

	public getSqueezeCredentials() {
		return {
			username: this.sqzConfig.v2.username as string,
			password: this.sqzConfig.v2.password as string,
		};
	}

	/**
	 * Sets the System User Credentials
	 * @param user
	 * @param pwd
	 * @private
	 */
	private setSqzSystemCredentials(user: string, pwd: string) {
		// taken from https://developer.mozilla.org/en-US/docs/Glossary/Base64#solution_1_%E2%80%93_escaping_the_string_before_encoding_it
		this.sqzConfig.system.username = unescape(encodeURIComponent(user));
		this.sqzConfig.system.password = unescape(encodeURIComponent(pwd));
	}

	/**
	 * Deletes the System User Credentials
	 * @private
	 */
	private unsetSqzSystemCredentials() {
		delete this.sqzConfig.system.username;
		delete this.sqzConfig.system.password;
	}

	public getSqzSystemCredentials() {
		return {
			user: this.sqzConfig.system.username as string,
			password: this.sqzConfig.system.password as string,
		};
	}

	public getSqueezeBasePath(): string {
		return this.sqzConfig.v2.basePath;
	}

	public getSqueezeApiKey(): string {
		return this.sqzConfig.apiKey;
	}

	public hasPHPSessionCookie(): boolean {
		const cookies = this.getCookies();
		return cookies.some(c => c.indexOf("PHPSESSID=") !== -1);
	}

	public clearPHPSessionCookie(): void {
		const cookies = this.getCookies();
		const cookie = cookies.find(c => c.indexOf("PHPSESSID=") !== -1);

		if (cookie) {
			// Clear cookie by setting expires date to the past
			document.cookie = "PHPSESSID=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/";
		}
		// document.cookie = "PHPSESSID=862c55c74c954bdece68d5906e23ef8f;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/"
	}

	private getCookies(): string[] {
		if (document.cookie == null) {
			return [];
		}

		return document.cookie.split(";").map(str => str.trim());
	}

	/**
	 * Tests if using any SQUEEZE API endpoint is possible.
	 */
	public async isAuthenticatedAtSqueeze(): Promise<boolean> {
		return await (this.squeeze.system.getVersion()
			.then(() => {
				return true;
			})
			.catch((err: Response) => {
				if (err.status == 401)
					return false;
				else throw err;
			}));
	}

	/**
	 * Tests if using any SQUEEZE SYSTEM API endpoint is possible.
	 */
	public async isAuthenticatedAtSqzSystem(): Promise<boolean> {
		return await (this.squeezeSystem.tenants.getTenants()
			.then(() => {
				return true;
			})
			.catch((err: Response) => {
				if (err.status == 401)
					return false;
				else {
					throw err;
				}
			}));
	}

	/**
	 * Indicates whether currently logged in user has the admin role.
	 */
	public async isAdminUser(): Promise<boolean> {
		return await (this.squeeze.user.getLoggedInUser()
			.then((user: User) => {
				const userRoles = user.roles;

				if(userRoles && userRoles.length > 0) {
					for(const role of userRoles) {
						if(role.id === 1) {
							return true;
						}
					}
				}

				return false;
			})
			.catch((err: Response) => {
				if (err.status == 401)
					return false;
				else throw err;
			}))
	}

	/**
	 * Build Document Download URL
	 * @param documentId
	 */
	public buildDocumentDownloadUrl(documentId: number) {
		return this.sqzConfig.v2.basePath + '/documents/' + documentId + '/attachments.zip';
	}

	// endregion

	// region Singleton

	private static instance: ClientManager;

	public static getInstance() {
		if(this.instance == null) {
			this.instance = new ClientManager(null, null);
		}

		return this.instance;
	}

	public static initInstance(url: string | null, apiKey: string | null) {
		if(this.instance == null) {
			this.instance = new ClientManager(url, apiKey);
		}

		return this.instance;
	}

	// endregion

	/**
	 * Gets and sets the possible authentication-types
	 */
	public async setPossibleAuthentications() {
		// Call the AuthConfig endpoint to get the allowed authentication types
		const authConfig = await this.squeeze.public.getPublicAuthConfig();

		// Is Login via Username/Password allowed?
		this.login.basicAvailable = authConfig.basicAuth || false;

		// Check if OAuth2 is active
		if (authConfig.oauthConfig?.active) {
			const openIdConnectUrl = authConfig.oauthConfig?.openIdConnectUrl;
			if (!openIdConnectUrl) {
				throw Error("No OpenIdConnectUrl defined!");
			}

			switch (authConfig.oauthConfig?.type) {
			case OAuth2Type.Keycloak:
				this.login.keyCloakAvailable = true;
				this.setKeyCloakTokenEndpoint(openIdConnectUrl);
				break;
			case OAuth2Type.Portal:
				this.login.portalAvailable = true;
				break;
			default:
				throw Error("Unknown OAuth2Type: " + authConfig.oauthConfig?.type);
			}
		}
	}

	/** Sets the used Authorization type */
	public setLoginType(loginType: AuthTypes) {
		this.login.activeAuth = loginType;
	}

	/**
	 * Redirects to the portal login route
	 */
	public portalLogin() {
		this.login.activeAuth = AuthTypes.Bearer;
		localStorage.setItem("authorization", "bearer");
		window.location.replace(this.baseUrl + "/ui/v1/portal");
	}

	/**
	 * Checks that appropriate OAuth methods are available when Bearer is used
	 */
	public async isAuthorizationMethodValid() {
		const authorization = localStorage.getItem("authorization");

		// If Authorization is bearer, check if keycloak is configured.
		if (authorization && authorization === "bearer" && !ClientManager.getInstance().keycloak) {
			// If not, fetch the possible authentication methods to check if portal is available
			await this.setPossibleAuthentications();
			// If portal is not available, redirect to keycloak login
			if (!this.login.portalAvailable) {
				return false;
			}
		}

		return true;
	}

	// region Keycloak
	/**
	 * Sets the Token-Endpoint for Keycloak
	 * @param openIdConnectUrl
	 * @private
	 */
	private setKeyCloakTokenEndpoint(openIdConnectUrl: string) {
		const urlSplit = openIdConnectUrl.split("/realms/");
		if (urlSplit[0] && urlSplit[1]) {
			const url = urlSplit[0];
			const realm = urlSplit[1].substr(0, urlSplit[1].indexOf("/"));
			this.keycloakOptions.url = url;
			this.keycloakOptions.realm = realm;
		}
	}

	/** Interval handle for polling refresh of the token */
	refreshTokenInterval: number | undefined;
	/**
	 */
	public async keyCloakLogin() {
		this.login.activeAuth = AuthTypes.Bearer;
		localStorage.setItem("authorization", "bearer");
		await this.openKeyCloakLogin();
	}

	/**
	 * Init Key Cloak an open login Dialog
	 */
	public async openKeyCloakLogin() {
		if (!this.keycloak) {
			this.keycloak = (Keycloak as any)(this.keycloakOptions);
		}

		if (!this.keycloak) {
			return;
		}

		return this.keycloak.init({ onLoad: this.keycloakOptions.onLoad, redirectUri: this.keycloakOptions.redirectUri, checkLoginIframe: false } as KeycloakInitOptions);
	}

	/**
	 * Checks if the Key Cloak session is valid. If it is, the token will be refreshed automatically
	 */
	public async checkKeyCloakLogin(): Promise<boolean> {
		this.login.activeAuth = AuthTypes.Bearer;
		localStorage.setItem("authorization", "bearer");
		this.login.keyCloakAvailable = true;
		if (!this.keycloak) {
			this.keycloak = (Keycloak as any)(this.keycloakOptions);
		}

		if (!this.keycloak) {
			return false;
		}

		return this.keycloak.init({ onLoad: this.keycloakOptions.onLoad, checkLoginIframe: false } as KeycloakInitOptions).then((auth: boolean) => {
			this.keycloakOptions.token = this.keycloak!.token!;

			//Token Refresh
			setInterval(() => {
				this.keycloak!.updateToken(70).then((refreshed: boolean) => {
					if (refreshed) {
						this.keycloakOptions.token = this.keycloak!.token!;
					}
				}).catch(() => {/**/});
			}, 10000)

			return auth;
		})
	}

	/**
	 * Triggers an logout at keycloak
	 */
	public async keycloakLogout() {
		if (!this.keycloak) {
			return;
		}
		const logoutOptions = { redirectUri: this.keycloakOptions.redirectUri  };
		window.location.href = this.keycloak.createLogoutUrl(logoutOptions);
	}

	/** Resets all keycloak token data */
	public resetKeyCloakTokens() {
		this.keycloakOptions.token = "";
		this.keycloakOptions.refreshToken = "";
		localStorage.removeItem("authorization");
		localStorage.removeItem("fullPath");
	}
	// endregion

}
