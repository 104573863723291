import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, toDisplayString as _toDisplayString, Transition as _Transition, createBlock as _createBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a3b6ba00"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sidenav-block p-mt-4" }
const _hoisted_2 = {
  key: 0,
  class: "mdi mdi-blur",
  style: {"padding-bottom":"2.4rem","color":"var(--dex-primary-color)"}
}
const _hoisted_3 = {
  key: 1,
  class: "p-text-bold",
  style: {"margin":"0 0 1rem 0"}
}
const _hoisted_4 = { class: "links" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "sidenav-block p-mb-4" }
const _hoisted_7 = { class: "flex p-flex-column" }
const _hoisted_8 = { class: "p-col" }
const _hoisted_9 = { class: "p-d-flex p-jc-between" }
const _hoisted_10 = { class: "userinfo p-text-bold" }
const _hoisted_11 = { class: "userinfo email" }
const _hoisted_12 = { class: "userinfo client-title" }
const _hoisted_13 = { class: "p-as-center" }
const _hoisted_14 = { class: "p-col" }
const _hoisted_15 = {
  key: 0,
  class: "mdi mdi-alert-outline icon-error"
}
const _hoisted_16 = { class: "p-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_v_template = _resolveComponent("v-template")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!
  const _component_ResetPasswordForm = _resolveComponent("ResetPasswordForm")!
  const _component_EntryDialog = _resolveComponent("EntryDialog")!
  const _component_DocumentUpload = _resolveComponent("DocumentUpload")!
  const _component_UserSettings = _resolveComponent("UserSettings")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_News = _resolveComponent("News")!
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _directive_badge = _resolveDirective("badge")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["p-component sidenav", {'sidenav-open': _ctx.opened}]),
      style: _normalizeStyle(_ctx.hideNavbar ? 'display: none;' : '')
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createVNode(_component_router_link, {
            to: { name: 'SqueezeDashboard', params: 'de'},
            style: {"text-decoration":"none","color":"white"}
          }, {
            default: _withCtx(() => [
              (!_ctx.opened)
                ? (_openBlock(), _createElementBlock("i", _hoisted_2))
                : (_openBlock(), _createElementBlock("h1", _hoisted_3, "DEX"))
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.accessibleLinks, (link) => {
            return (_openBlock(), _createBlock(_component_router_link, {
              key: link.to,
              to: link.to
            }, {
              default: _withCtx(({ isActive, isExactActive }) => [
                _createElementVNode("span", {
                  class: _normalizeClass(
						[
							(link.label === 'Admin' && isActive) && _ctx.opened ? 'link-active-border' : '',
							(link.label !== 'Admin' && isExactActive) && _ctx.opened ? 'link-active-border' : '',
							'link'
						])
                }, [
                  (link.label === 'Admin' && _ctx.store.state.errorMigrations.length > 0)
                    ? _withDirectives((_openBlock(), _createElementBlock("i", {
                        key: 0,
                        class: _normalizeClass([(link.label === 'Admin' && isActive) || (link.label !== 'Admin' && isExactActive) ? 'link-active-icon' : '', link.icon])
                      }, null, 2)), [
                        [
                          _directive_badge,
                          void 0,
                          void 0,
                          { danger: true }
                        ]
                      ])
                    : (_openBlock(), _createElementBlock("i", {
                        key: 1,
                        class: _normalizeClass([(link.label === 'Admin' && isActive) || (link.label !== 'Admin' && isExactActive) ? 'link-active-icon' : '', link.icon])
                      }, null, 2)),
                  _createVNode(_Transition, { name: "fade" }, {
                    default: _withCtx(() => [
                      (_ctx.opened)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(link.label), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1024)
                ], 2)
              ]),
              _: 2
            }, 1032, ["to"]))
          }), 128))
        ]),
        (_ctx.store.state.featureSet.uiAllowDocumentUpload)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              onClick: _ctx.openUploadDialog,
              label: _ctx.opened ? _ctx.$t('Squeeze.General.Upload') : '',
              icon: "mdi mdi-cloud-upload-outline",
              class: "upload-button p-button-text",
              style: _normalizeStyle(_ctx.opened ? 'padding-left: 0;' : '')
            }, null, 8, ["onClick", "label", "style"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_6, [
        (_ctx.numberOfUpdates)
          ? (_openBlock(), _createBlock(_component_v_template, { key: 0 }, {
              default: _withCtx(() => [
                _withDirectives(_createVNode(_component_Avatar, {
                  label: _ctx.loggedInUserInitials,
                  onClick: _ctx.toggleUserInfo,
                  class: _normalizeClass([_ctx.opened ? 'avatar-opened' : 'avatar-closed', 'avatar']),
                  size: "large",
                  shape: "circle",
                  haspopup: true,
                  "aria-controls": "overlay_panel"
                }, null, 8, ["label", "onClick", "class"]), [
                  [
                    _directive_badge,
                    void 0,
                    void 0,
                    { danger: true }
                  ],
                  [
                    _directive_badge,
                    _ctx.numberOfUpdates,
                    void 0,
                    { value: true }
                  ]
                ])
              ]),
              _: 1
            }))
          : (_openBlock(), _createBlock(_component_v_template, { key: 1 }, {
              default: _withCtx(() => [
                _createVNode(_component_Avatar, {
                  label: _ctx.loggedInUserInitials,
                  onClick: _ctx.toggleUserInfo,
                  class: _normalizeClass([_ctx.opened ? 'avatar-opened' : 'avatar-closed', 'avatar']),
                  size: "large",
                  shape: "circle",
                  haspopup: true,
                  "aria-controls": "overlay_panel"
                }, null, 8, ["label", "onClick", "class"])
              ]),
              _: 1
            })),
        _createVNode(_component_OverlayPanel, {
          ref: "op",
          id: "overlay_panel"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", null, [
                    _createElementVNode("p", _hoisted_10, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.store.state.user && _ctx.store.state.user.firstName) + " " + _toDisplayString(_ctx.store.state.user && _ctx.store.state.user.lastName), 1)
                    ]),
                    _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.store.state.user && _ctx.store.state.user.email), 1),
                    _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.store.state.systemInformation.tenantName), 1)
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    (_ctx.store.state.featureSet.newsSidebar)
                      ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                          key: 0,
                          class: _normalizeClass(["p-button p-button-rounded p-button-text", [_ctx.numberOfUpdates > 0 ? 'ring-button' :'']]),
                          icon: "mdi mdi-bell-outline",
                          onClick: _ctx.openNewsSidebar
                        }, null, 8, ["class", "onClick"])), [
                          [_directive_tooltip, _ctx.$t('Squeeze.General.News.News')]
                        ])
                      : _createCommentVNode("", true),
                    _withDirectives(_createVNode(_component_Button, {
                      class: "p-button p-button-rounded p-button-text",
                      icon: "mdi mdi-cog",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showSettingDialog = !_ctx.showSettingDialog))
                    }, null, 512), [
                      [_directive_tooltip, _ctx.$t('Squeeze.UserSettings.Settings')]
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_component_Dropdown, {
                  class: "p-ml-auto language-dropdown",
                  modelValue: _ctx.selectedLanguage,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedLanguage) = $event)),
                  options: _ctx.languages,
                  optionLabel: "description",
                  optionValue: "countryCode",
                  placeholder: _ctx.$t('Squeeze.Language.Selection'),
                  onChange: _ctx.changeLanguage
                }, {
                  option: _withCtx((slotProps) => [
                    _withDirectives((_openBlock(), _createElementBlock("div", null, [
                      _createElementVNode("span", null, _toDisplayString(slotProps.option.description), 1),
                      (!_ctx.SupportedLangs.includes(slotProps.option.countryCode))
                        ? (_openBlock(), _createElementBlock("i", _hoisted_15))
                        : _createCommentVNode("", true)
                    ])), [
                      [
                        _directive_tooltip,
                        !_ctx.SupportedLangs.includes(slotProps.option.countryCode) ? _ctx.$t('Squeeze.General.LanguageNotAvailable') : '',
                        void 0,
                        { bottom: true }
                      ]
                    ])
                  ]),
                  _: 1
                }, 8, ["modelValue", "options", "placeholder", "onChange"])
              ]),
              _createElementVNode("div", _hoisted_16, [
                (!_ctx.store.state.user.externalUser)
                  ? (_openBlock(), _createBlock(_component_Button, {
                      key: 0,
                      class: "p-button-sm p-mb-2 p-button-outlined logout-button",
                      label: _ctx.$t('Squeeze.Login.ChangePassword'),
                      icon: "mdi mdi-lock-reset",
                      onClick: _ctx.openResetPasswordDialog
                    }, null, 8, ["label", "onClick"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_Button, {
                  class: "p-button-sm p-button-outlined logout-button",
                  label: _ctx.$t('Squeeze.Login.Logout'),
                  icon: "mdi mdi-logout-variant",
                  onClick: _ctx.logout
                }, null, 8, ["label", "onClick"])
              ])
            ])
          ]),
          _: 1
        }, 512),
        _createVNode(_component_Button, {
          onClick: _ctx.onCollapseSidebar,
          class: "p-button-sm p-mt-3",
          label: _ctx.opened ? _ctx.$t('Squeeze.General.SidenavButton') : '',
          icon: _ctx.opened ? 'mdi mdi-chevron-double-left' : 'mdi mdi-chevron-double-right'
        }, null, 8, ["onClick", "label", "icon"])
      ])
    ], 6),
    _createVNode(_component_EntryDialog, {
      show: _ctx.showDialog,
      onOnClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showDialog = false)),
      headerText: _ctx.$t('Squeeze.Login.ChangePassword'),
      showSaveButton: false,
      showAbortButton: false
    }, {
      content: _withCtx(() => [
        _createVNode(_component_ResetPasswordForm, {
          onOnClickSend: _ctx.resetPassword,
          loading: _ctx.loadingDialog
        }, null, 8, ["onOnClickSend", "loading"])
      ]),
      _: 1
    }, 8, ["show", "headerText"]),
    _createVNode(_component_EntryDialog, {
      show: _ctx.showUploadDialog,
      onOnClose: _cache[3] || (_cache[3] = ($event: any) => {_ctx.showUploadDialog = false, _ctx.$emit('isUploadDialogShown', false);}),
      headerText: _ctx.$t('Squeeze.General.Upload'),
      showSaveButton: false,
      showAbortButton: false
    }, {
      content: _withCtx(() => [
        _createVNode(_component_DocumentUpload)
      ]),
      _: 1
    }, 8, ["show", "headerText"]),
    _createVNode(_component_Dialog, {
      visible: _ctx.showSettingDialog,
      "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showSettingDialog) = $event)),
      style: {"width":"60rem"},
      contentClass: "setting-dialog",
      modal: true,
      showHeader: false
    }, {
      default: _withCtx(() => [
        _createVNode(_component_UserSettings, {
          loggedInUserInitials: _ctx.loggedInUserInitials,
          onClickSettingDialog: _ctx.clickSettingDialog
        }, null, 8, ["loggedInUserInitials", "onClickSettingDialog"])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_Sidebar, {
      visible: _ctx.showNewsSidebar,
      "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.showNewsSidebar) = $event)),
      position: "right",
      class: "p-sidebar-md",
      modal: true,
      showCloseIcon: true,
      dismissable: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_News)
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}