
import {defineComponent, nextTick, ref} from 'vue';
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";
import { useI18n } from 'vue-i18n';
import { useToast } from "primevue/usetoast";
import SystemImport from "@/apps/administration/components/system/SystemImport.vue";
import EntryDialog from "@/components/EntryDialog.vue";
import {UploadFile} from "@/shims-prime-vue";

export default defineComponent({
	name: "XmlMapperTestView",
	components: {
		EntryDialog,
		SystemImport,
	},
	props: {},
	setup() {
		const {t} = useI18n();
		const toast = useToast();

		/** List of all files */
		const files = ref<UploadFile[]>([]);

		/** Used File-Type for Upload */
		const fileType = ref('xml, .pdf');

		/** Should the Entry-Dialog for classifications be shown? */
		const showDialog = ref<boolean>(false);

		/**
		 * Manual file upload to the Squeeze API. This has been programmed because the generated API client does not
		 * support multipart/form-data requests: https://github.com/swagger-api/swagger-codegen/issues/3921
		 * @param file
		 * @returns Object with the id of the created document
		 */
		const manualFileUpload = async (file: UploadFile) => {
			const body = new FormData();
			body.set("document", file);

			const response = await ClientManager.getInstance().customFetch(ClientManager.getInstance().getSqueezeBasePath() +
				"/xml/xrechnung/validate", {
				method: "POST",
				body: body,
			});

			if (response.status !== 200 && response.status !== 204) {
				const responseJSON = await response.json();
				throw new Error("Unexpected status " + responseJSON.message);
			} else {
				return response.text();
			}
		}

		/**
		 * Starts the File upload for the given files
		 */
		const startFileUpload = () => {
			files.value
				.forEach((file: any, index: number) => {
					const idx = index;
					files.value[idx].error = false;
					files.value[idx].errorText = "";
					files.value[idx].loading = true;
					files.value = [...files.value];

					manualFileUpload(file)
						.then(async (response: string) => {
							files.value[idx].uploadFinished = true;
							showDialog.value = true;
							await nextTick();
							// we need to wait for the iframe to be loaded
							(document.getElementById('testDocumentIframe') as any).srcdoc = response;
							files.value = [];
						})
						.catch(err => {
							files.value[idx].error = true;
							files.value[idx].errorText = err.message;
							ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
						})
						.finally(() => {
							files.value[idx].loading = false;
							files.value = [...files.value];
						});
				})
		}

		/** Uploads the files from the file-uploader
		 * @param filesSend
		 */
		const fileUploader = (filesSend: UploadFile[]) => {
			files.value = filesSend;
			startFileUpload();
		}

		return {
			t,
			toast,
			files,
			fileType,
			showDialog,
			fileUploader,
		}
	},
})
